import * as React from 'react';

import { imageConfiguratorStyles as sx } from './imageConfiguratorStyles';

import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Toolbar,
  Typography,
  styled
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import ReplayIcon from '@mui/icons-material/Replay';
import CheckIcon from '@mui/icons-material/Check';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTranslation } from 'react-i18next';
import ResultImage from '../result-image/ResultImage';

const PATH_PREFIX = 'combination';
const PATH_CAPTCHA = PATH_PREFIX + '/captcha';

const MAX_OUTLET_LENGTH = 5;

const HorizontalSelected = styled(Box)({
  ...sx.orientationStyle.horizontal,
  ...sx.orientationStyle.selected
});

const HorizontalNotSelected = styled(Box)({
  ...sx.orientationStyle.horizontal,
  ...sx.orientationStyle.notSelected
});

const VerticalSelected = styled(Box)({
  ...sx.orientationStyle.vertical,
  ...sx.orientationStyle.selected
});

const VerticalNotSelected = styled(Box)({
  ...sx.orientationStyle.vertical,
  ...sx.orientationStyle.notSelected
});

export default function ImageConfigurator() {
  const { t } = useTranslation();

  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [captchaSolved, setCaptchaSolved] = React.useState(false);
  const [captchaImageKey, setCaptchaImageKey] = React.useState(
    Math.random() * 100000000000000
  );
  const [captchaInput, setCaptchaInput] = React.useState('');
  const [captchaInputNotCorrect, setCaptchaInputNotCorrect] = React.useState(
    false
  );

  const [combinedPath, setCombinedPath] = React.useState(PATH_PREFIX);
  const [frame, setFrame] = React.useState('');
  const [orientation, setOrientation] = React.useState('H');
  const [outlets, setOutlets] = React.useState([
    { insert: '', cover: '', designCover: '' }
  ]);
  const [formatImage, setFormatImage] = React.useState('JPG');
  const [sizeImage, setSizeImage] = React.useState('400');
  const [quality, setQuality] = React.useState(8);

  const viewportLessThanMD = useMediaQuery(useTheme().breakpoints.down('md'));
  const viewportLessThanSM = useMediaQuery(useTheme().breakpoints.down('sm'));

  const outletElement = React.useRef();

  let hasStackingOutlet = false;
  if (outletElement && outletElement.current) {
    const heightOutlet = outletElement.current.offsetHeight;
    if (Number.isInteger(heightOutlet) && heightOutlet > 100) {
      hasStackingOutlet = true;
    }
  }

  const imageActive =
    frame ||
    outlets.some((outletItem) => {
      return outletItem.insert || outletItem.cover || outletItem.designCover;
    });

  React.useEffect(() => {
    if (!captchaSolved) {
      fetch(PATH_PREFIX + '/checkCaptchaSession', {
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      })
        .then((result) => result.json())
        .then(
          (result) => {
            setCaptchaSolved(result.captchaSolved);
            setLoading(false);
          },
          (error) => {
            setError(true);
            setLoading(false);
          }
        );
    }
  }, [captchaSolved]);

  const updateCaptchaAttempt = () => {
    if (!captchaInput) return;

    fetch(PATH_PREFIX + '/captchaAttempt', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ captchaText: captchaInput })
    })
      .then((result) => result.json())
      .then(
        (result) => {
          setCaptchaSolved(result.captchaSolved);
          if (!result.captchaSolved) {
            setCaptchaInputNotCorrect(true);
          } else {
            setCaptchaInputNotCorrect(false);
          }

          setLoading(false);
        },
        (error) => {
          setError(true);
          setLoading(false);
        }
      );
  };

  const reloadCaptchaImage = () => {
    setCaptchaInputNotCorrect(false);
    setCaptchaInput('');
    setCaptchaImageKey(Math.random() * 100000000000000);
  };

  const updateCombinedPath = React.useCallback(() => {
    let path = PATH_PREFIX + '/combineImages';

    path += '?o=' + orientation;
    path += '&s=' + sizeImage;
    path += '&f=' + formatImage;
    path += '&q=' + quality;

    const requestFrame = frame ? frame.toUpperCase() : '';
    path += '&fr=' + requestFrame;

    const combindedOutletLength = Math.min(outlets.length, MAX_OUTLET_LENGTH);

    for (let i = 0; i < combindedOutletLength; i++) {
      const outletItem = outlets[i];
      const outletNumber = i + 1;

      const requestInsert = outletItem.insert
        ? outletItem.insert.toUpperCase()
        : '';
      path += '&i' + outletNumber + '=' + requestInsert;

      const requestCover = outletItem.cover
        ? outletItem.cover.toUpperCase()
        : '';
      path += '&c' + outletNumber + '=' + requestCover;

      const requestDesignCover = outletItem.designCover
        ? outletItem.designCover.toUpperCase()
        : '';
      path += '&dc' + outletNumber + '=' + requestDesignCover;
    }

    path = path.replace(/\s/g, '');

    setCombinedPath(path);
  }, [formatImage, frame, orientation, outlets, quality, sizeImage]);

  React.useEffect(() => {
    updateCombinedPath();
  }, [updateCombinedPath, orientation]);

  const changeOrientation = (event) => {
    setOrientation(event.target.value);
  };

  const changeOutletValue = (index, outletInputType, newValue) => {
    setOutlets((items) => [
      ...items.slice(0, index),
      {
        ...items[index],
        [outletInputType]: newValue
      },
      ...items.slice(index + 1)
    ]);
  };

  const addOutletValue = () => {
    if (outlets.length === 5) return;

    setOutlets((items) => [
      ...items,
      { insert: '', cover: '', designCover: '' }
    ]);
  };

  const removeOutletValue = (targetIndex) => {
    if (outlets.length === 1) return;

    setOutlets((prevOutlets) =>
      prevOutlets.filter((_, index) => index !== targetIndex)
    );
  };

  if (loading) {
    return (
      <>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid sm={12} md={6} sx={viewportLessThanMD ? {} : sx.desktopHeight}>
            <Box
              sx={{
                height: '10rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <CircularProgress />
            </Box>
          </Grid>
          <Grid sm={12} md={6}></Grid>
        </Grid>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid sm={12} md={6} sx={viewportLessThanMD ? {} : sx.desktopHeight}>
            <Alert
              severity="error"
              sx={{ fontSize: '1.2rem', marginTop: '1rem' }}
            >
              <AlertTitle sx={{ fontSize: '1.2rem' }}>Error</AlertTitle>
              {t('errorText')}
            </Alert>
          </Grid>
          <Grid sm={12} md={6}></Grid>
        </Grid>
      </>
    );
  }

  if (!loading && !captchaSolved) {
    return (
      <>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid sm={12} md={6} sx={viewportLessThanMD ? {} : sx.desktopHeight}>
            <Paper elevation={3} sx={sx.captchaWrap}>
              <Typography variant={'h6'} sx={sx.captcha}>
                {t('captchaTitle')}
              </Typography>
              <Box sx={sx.captcha}>
                <img
                  key={captchaImageKey}
                  id="captchaImage"
                  alt="captchaImage"
                  src={
                    PATH_CAPTCHA +
                    '?retrieveingFreshCaptchaImage=' +
                    captchaImageKey
                  }
                />
              </Box>
              <TextField
                variant="standard"
                sx={
                  viewportLessThanMD
                    ? sx.captchaInputStyleSmall
                    : sx.captchaInputStyleBig
                }
                id="imageConfiguratorCaptchaInput"
                label={
                  captchaInputNotCorrect
                    ? t('captchaNotCorrect')
                    : t('captchaInput')
                }
                value={captchaInput}
                error={captchaInputNotCorrect ? true : false}
                onChange={(event) => {
                  setCaptchaInput(event.target.value);
                  setCaptchaInputNotCorrect(false);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    updateCaptchaAttempt();
                    event.preventDefault();
                  }
                }}
              />
              <Stack spacing={2} direction="row" sx={sx.captchaButtonsWrap}>
                <Button
                  startIcon={<CheckIcon />}
                  variant="contained"
                  onClick={updateCaptchaAttempt}
                >
                  {t('ok')}
                </Button>
                <Button
                  startIcon={<ReplayIcon />}
                  variant="contained"
                  onClick={reloadCaptchaImage}
                >
                  {t('reload')}
                </Button>
              </Stack>
            </Paper>
          </Grid>
          <Grid sm={12} md={6}></Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent={imageActive ? 'space-between' : 'center'}
        alignItems={imageActive ? 'stretch' : 'center'}
      >
        <Grid sm={12} md={6} sx={viewportLessThanMD ? {} : sx.desktopHeight}>
          <Box sx={sx.wrapStyle.configuratorLine}>
            <TextField
              variant="standard"
              sx={
                viewportLessThanSM
                  ? sx.frameInputStyleSmall
                  : sx.frameInputStyleBig
              }
              id="imageConfiguratorFrameInput"
              label={t('frame')}
              value={frame}
              onChange={(event) => {
                setFrame(event.target.value);
              }}
              onBlur={updateCombinedPath}
            />
            <FormControl
              sx={
                viewportLessThanSM
                  ? sx.orientationStyle.marginSM
                  : sx.orientationStyle.marginMD
              }
            >
              <RadioGroup
                row
                value={orientation}
                onChange={changeOrientation}
                name="orientation-buttons-group"
              >
                <FormControlLabel
                  value="H"
                  control={
                    <Radio
                      icon={<HorizontalNotSelected />}
                      checkedIcon={<HorizontalSelected />}
                    />
                  }
                  label=""
                />
                <FormControlLabel
                  sx={{ position: 'relative', bottom: '1rem' }}
                  value="V"
                  control={
                    <Radio
                      icon={<VerticalNotSelected />}
                      checkedIcon={<VerticalSelected />}
                    />
                  }
                  label=""
                />
              </RadioGroup>
            </FormControl>
          </Box>
          {outlets.map((item, index) => (
            <Box
              key={index}
              sx={[sx.wrapStyle.configuratorLine, sx.wrapStyle.outletLine]}
              ref={outletElement}
            >
              <Grid spacing={2} container sx={{ flexGrow: 1 }}>
                <Grid>
                  <TextField
                    variant="standard"
                    sx={
                      viewportLessThanMD
                        ? sx.textInputStyleSmall
                        : sx.textInputStyleBig
                    }
                    id={'imageConfiguratorCoverInput' + (index + 1)}
                    label={t('cover')}
                    value={item.cover}
                    onChange={(event) => {
                      changeOutletValue(index, 'cover', event.target.value);
                    }}
                    onBlur={updateCombinedPath}
                  />
                </Grid>
                <Grid>
                  <TextField
                    variant="standard"
                    sx={
                      viewportLessThanMD
                        ? sx.textInputStyleSmall
                        : sx.textInputStyleBig
                    }
                    id={'imageConfiguratorInsertInput' + (index + 1)}
                    label={t('insert')}
                    value={item.insert}
                    onChange={(event) => {
                      changeOutletValue(index, 'insert', event.target.value);
                    }}
                    onBlur={updateCombinedPath}
                  />
                </Grid>
                <Grid>
                  <TextField
                    variant="standard"
                    sx={
                      viewportLessThanMD
                        ? sx.textInputStyleSmall
                        : sx.textInputStyleBig
                    }
                    id={'imageConfiguratorDesignCoverInput' + (index + 1)}
                    label={t('designCover')}
                    value={item.designCover}
                    onChange={(event) => {
                      changeOutletValue(
                        index,
                        'designCover',
                        event.target.value
                      );
                    }}
                    onBlur={updateCombinedPath}
                  />
                </Grid>
                <Grid xsOffset={hasStackingOutlet ? 'auto' : 0}>
                  {index >= 1 && (
                    <IconButton
                      aria-label="deleteOutletRow"
                      size="large"
                      color="primary"
                      sx={sx.removeOutletButtonStyle}
                      onClick={() => {
                        removeOutletValue(index);
                      }}
                    >
                      <RemoveCircleIcon sx={sx.iconButtonStyle} />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Box>
          ))}
          {outlets.length < MAX_OUTLET_LENGTH && (
            <Box sx={sx.wrapStyle.configuratorLine}>
              <Toolbar sx={sx.addOutletStyle}>
                <Button
                  sx={sx.addOutletButtonStyle}
                  endIcon={
                    <AddCircleIcon
                      sx={sx.iconButtonStyle}
                      style={{ fontSize: 35 }}
                    />
                  }
                  size="large"
                  onClick={addOutletValue}
                >
                  {viewportLessThanSM ? '' : t('addOutletItem')}
                </Button>
              </Toolbar>
            </Box>
          )}

          {viewportLessThanMD ? (
            <ResultImage
              imageActive={imageActive}
              combinedPath={combinedPath}
            />
          ) : null}

          <Box sx={[sx.wrapStyle.configuratorLine, sx.wrapStyle.exportLine]}>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
              <Grid>
                <TextField
                  variant="standard"
                  sx={sx.textInputStyle}
                  id="imageConfiguratorImageSizeInput"
                  label={t('imageSize')}
                  value={sizeImage}
                  onChange={(event) => {
                    setSizeImage(event.target.value);
                  }}
                  onBlur={updateCombinedPath}
                />
              </Grid>
              <Grid
                sx={{
                  marginTop: '1.4rem'
                }}
              >
                <Box sx={sx.selectInputStyle.wrap}>
                  <Typography variant="subtitle1" display="inline">
                    {t('fileFormat')}
                  </Typography>
                  <TextField
                    select
                    className="imageConfiguratorTextfieldSelect"
                    inputProps={{ IconComponent: ExpandMoreIcon }}
                    size="small"
                    sx={sx.selectInputStyle.select}
                    label=""
                    InputLabelProps={{ shrink: false }}
                    value={formatImage}
                    onChange={(event) => setFormatImage(event.target.value)}
                    onBlur={updateCombinedPath}
                  >
                    <MenuItem value={'PNG'}>PNG</MenuItem>
                    <MenuItem value={'JPG'}>JPEG</MenuItem>
                  </TextField>
                </Box>
                {formatImage === 'JPG' ? (
                  <Box sx={sx.selectInputStyle.wrap}>
                    <Typography variant="subtitle1" display="inline">
                      {t('imageQuality')}
                    </Typography>
                    <TextField
                      select
                      className="imageConfiguratorTextfieldSelect"
                      inputProps={{ IconComponent: ExpandMoreIcon }}
                      size="small"
                      sx={sx.selectInputStyle.select}
                      label=""
                      InputLabelProps={{ shrink: false }}
                      value={quality}
                      onChange={(event) => setQuality(event.target.value)}
                      onBlur={updateCombinedPath}
                    >
                      {Array.from(Array(10), (_, i) => {
                        return (
                          <MenuItem key={i + 1} value={i + 1}>
                            {i + 1}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Box>
                ) : (
                  <Box sx={{ width: '20rem' }}></Box>
                )}
              </Grid>
              <Grid xsOffset="auto" sx={sx.exportButtonWrap}>
                {imageActive ? (
                  <a id="download" href={combinedPath} download>
                    <Button variant="contained" sx={sx.exportButtonStyle}>
                      {t('export')}
                    </Button>
                  </a>
                ) : (
                  <Button
                    disabled
                    variant="contained"
                    sx={sx.exportButtonStyle}
                  >
                    {t('export')}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid sm={12} md={6}>
          {viewportLessThanMD ? null : (
            <ResultImage
              imageActive={imageActive}
              combinedPath={combinedPath}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
