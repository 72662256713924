import * as React from 'react';

import { imageConfiguratorStyles as sx } from '../image-configurator/imageConfiguratorStyles';

import { Paper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';

import { useTranslation } from 'react-i18next';

export default function ResultImage({ imageActive, combinedPath }) {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid></Grid>
      <Grid sx={[sx.resultWrap, imageActive ? sx.imageResultWrap : null]}>
        {imageActive ? (
          <Box
            component="img"
            id="combinationImage"
            alt="combinationImage"
            src={combinedPath}
            sx={sx.imageResult}
          />
        ) : (
          <Paper elevation={3} sx={sx.imagePlaceholder}>
            <Box>{t('imagePlaceholder')}</Box>
          </Paper>
        )}
      </Grid>
      <Grid></Grid>
    </Grid>
  );
}
