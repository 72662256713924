export const imageConfiguratorStyles = {
  titleStyle: {
    overflowWrap: 'break-word',
    hyphens: 'auto'
  },
  wrapStyle: {
    configuratorLine: {
      paddingLeft: '0.4rem',
      margin: '1rem 0',
      minHeight: '5rem',
      clear: 'both'
    },
    outletLine: {
      borderRadius: '4px',
      '&:hover': {
        background: '#eeeeee'
      }
    }
  },
  captchaWrap: {
    marginTop: '1rem',
    padding: '2rem'
  },
  captcha: {
    height: '4rem',
    marginTop: '1rem'
  },
  captchaInputStyleBig: {
    marginLeft: '0.7rem',
    minWidth: '12.7rem'
  },
  captchaInputStyleSmall: {
    marginLeft: '0.7rem',
    minWidth: '7rem'
  },
  captchaButtonsWrap: {
    marginLeft: '1rem',
    marginTop: '0.5rem',
    display: 'inline-flex'
  },
  selectInputStyle: {
    wrap: {
      display: 'inline-flex',
      alignItems: 'center',
      marginRight: '0.4rem'
    },
    select: { m: 1 },
    padding: '3.5px 14px'
  },
  textInputStyle: {
    marginTop: '0.8rem'
  },
  textInputStyleBig: {
    marginTop: '0.8rem',
    minWidth: '12.7rem'
  },
  textInputStyleSmall: {
    marginTop: '0.8rem',
    minWidth: '7rem'
  },
  frameInputStyleBig: {
    marginTop: '0.8rem',
    minWidth: '12.7rem'
  },
  frameInputStyleSmall: {
    marginTop: '0.8rem',
    minWidth: '8rem'
  },
  orientationStyle: {
    marginMD: {
      marginLeft: '2rem'
    },
    marginSM: {
      marginLeft: '1rem'
    },
    horizontal: {
      width: '4rem',
      height: '2rem'
    },
    vertical: {
      width: '2rem',
      height: '4rem'
    },
    selected: {
      background: '#e7e7e7',
      outline: '2px solid #5b8aa4'
    },
    notSelected: {
      outline: '2px solid lightgrey'
    }
  },
  iconButtonStyle: {
    fontSize: 35
  },
  removeOutletButtonStyle: {
    position: 'relative',
    top: '0.3rem'
  },
  addOutletStyle: {
    height: '4.5rem',
    marginTop: '2rem',
    background: '#e7e7e7',
    boxShadow: 'inset 0 7px 10px -7px rgb(0 0 0 / 43%)'
  },
  addOutletButtonStyle: {
    marginLeft: 'auto',
    order: 2,
    fontSize: 20,
    textTransform: 'none'
  },
  exportButtonWrap: {
    alignItems: 'center',
    display: 'flex',
    height: '4.5rem'
  },
  exportButtonStyle: {
    textTransform: 'none',
    fontSize: 20,
    paddingLeft: '3rem',
    paddingRight: '3rem',
    marginTop: '1rem'
  },
  resultWrap: {
    position: 'relative',
    backgroundColor: 'white'
  },
  imageResultWrap: {
    height: '46rem',
    width: '46rem'
  },
  imageResult: {
    maxHeight: '100%',
    maxWidth: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto'
  },
  desktopHeight: { minHeight: '70vh' },
  imagePlaceholder: {
    width: '13rem',
    height: '13rem',
    padding: '3rem',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    fontSize: 'large'
  }
};
