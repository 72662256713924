import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const currentUrl = window.location.search;
const urlParams = new URLSearchParams(currentUrl);
const urlLanguage = urlParams.get('language');

let language = null;
if (urlLanguage && urlLanguage.length) {
  language = urlLanguage.split('-')[0];
  if (language.length > 2) {
    language = null;
  }
}

i18n.use(initReactI18next).init({
  debug: false,
  lng: urlLanguage,
  fallbackLng: 'de',
  interpolation: {
    escapeValue: false
  },
  resources: {
    en: {
      translation: {
        imageConfiguratorTitle: 'Image configurator',
        captchaTitle:
          'Please enter the displayed text to get to the image configurator',
        captchaInput: 'Enter text here',
        captchaNotCorrect: 'Mismatching',
        ok: 'Ok',
        reload: 'Reload',
        frame: 'Frame',
        cover: 'Cover',
        insert: 'Insert',
        designCover: 'Design cover',
        addOutletItem: 'Add cover and insert',
        imageSize: 'Image size',
        imageQuality: 'Quality',
        fileFormat: 'Image format',
        export: 'Export image',
        imagePlaceholder:
          'Please enter at least one frame, insert or cover first.',
        errorText: 'Error: Image configurator cannot be opened'
      }
    },
    de: {
      translation: {
        imageConfiguratorTitle: 'Bildkonfigurator',
        captchaTitle:
          'Bitte geben Sie den angezeigten Text an, um zum Bildkonfigurator zu gelangen',
        captchaInput: 'Hier eingeben',
        captchaNotCorrect: 'Nicht übereinstimmend',
        ok: 'Ok',
        reload: 'Neu laden',
        frame: 'Rahmen',
        cover: 'Abdeckung',
        insert: 'Einsatz',
        designCover: 'Designcover',
        addOutletItem: 'Abdeckung und Einsatz hinzufügen',
        imageSize: 'Bildgröße',
        imageQuality: 'Qualität',
        fileFormat: 'Bildformat',
        export: 'Bild exportieren',
        imagePlaceholder:
          'Bitte geben Sie zuerst mindestens einen Rahmen, einen Einsatz oder eine Abdeckung an.',
        errorText: 'Fehler: Bildkonfigurator kann nicht geöffnet werden'
      }
    }
  }
});

export default i18n;
