import * as React from "react";

import "./App.css";

import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";

import { i18n } from "./i18n"; // eslint-disable-line

import ImageConfigurator from "./components/image-configurator/ImageConfigurator";

const theme = createTheme({
  typography: {
    fontFamily: ["Mr Eaves XL Mod OT", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#5b8aa4",
      light: "#789eb3",
      dark: "#406173",
    },
    text: {
      primary: "#707070",
      secondary: "#595959",
      disabled: "#d3d3d3",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div
        className="ImageConfiguratorWrapper"
        style={{
          padding: 20,
        }}
      >
        <ImageConfigurator></ImageConfigurator>
      </div>
    </ThemeProvider>
  );
}

export default App;
